<template>
  <Teleport to="body">
    <div
      v-if="props.isOpen"
      class="bg-grays-dark/50 fixed inset-0 z-50 min-h-screen transition-opacity duration-300"
      @click="emit('close')"
    >
      <aside
        ref="drawerRef"
        class="absolute right-0 flex h-full w-full max-w-[320px] flex-col overflow-y-auto bg-white py-6 transition-transform duration-300"
        :style="{ transform: `translateX(${Math.max(0, -swipeDistance)}px)` }"
        @click.stop
      >
        <div
          class="flex items-center justify-end border-b border-[#E4E4E7] px-4 pb-4"
        >
          <ZenniIconsIconClose
            size="lg"
            class="text-grays-dark cursor-pointer"
            @click="emit('close')"
          />
        </div>
        <div class="flex flex-col justify-center">
          <div
            v-for="(category, index) in props.links.filter(
              (link) => link.isCategory,
            )"
            :key="index"
            class="border-b"
          >
            <button
              v-if="category.subcategories"
              class="flex w-full items-center justify-between p-3 font-bold"
              @click="toggleCategory(index)"
            >
              <span>{{ category.label }}</span>
              <ZenniIconsIconChevronDown
                size="sm"
                class="transition-transform duration-300"
                :class="{ 'rotate-180': expandedCategory === index }"
              />
            </button>
            <button
              v-else
              class="flex w-full items-center justify-between p-3 font-bold"
            >
              <nuxt-link :to="category.href" @click="emit('close')">
                {{ category.label }}
              </nuxt-link>
            </button>
            <div
              v-if="expandedCategory === index"
              class="flex flex-col pb-4 pl-8 pr-4"
            >
              <nuxt-link
                v-for="subcategory in category.subcategories ?? []"
                :key="subcategory.href"
                :to="subcategory.href"
                class="hover:text-teal-primary py-2"
                @click="emit('close')"
              >
                {{ subcategory.label }}
              </nuxt-link>
            </div>
          </div>
          <div class="flex flex-col justify-center px-4 pb-4">
            <nuxt-link
              v-for="link in props.links.filter((link) => !link.isCategory)"
              :key="link.href"
              :to="link.href"
              class="hover:text-teal-primary pt-4"
              @click="emit('close')"
            >
              {{ link.label }}
            </nuxt-link>
          </div>
        </div>
      </aside>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useSwipe } from '@vueuse/core'
import { ref, onMounted, onUnmounted, watch } from '#imports'

const emit = defineEmits(['close'])
const props = defineProps<{
  isOpen?: boolean
  links: {
    label: string
    href: string
    target?: string
    isCategory?: boolean
    subcategories?: {
      label: string
      href: string
    }[]
  }[]
}>()

const expandedCategory = ref<number | null>(null)
const drawerRef = ref<HTMLElement | null>(null)
const swipeDistance = ref(0)

const { lengthX } = useSwipe(drawerRef, {
  threshold: 0,
  onSwipe() {
    if (lengthX.value <= 0) {
      swipeDistance.value = lengthX.value
    }
  },
  onSwipeEnd() {
    if (
      Math.abs(swipeDistance.value) >
      (drawerRef.value?.offsetWidth || 0) * 0.3
    ) {
      emit('close')
    } else {
      swipeDistance.value = 0
    }
  },
})

const toggleCategory = (index: number) => {
  expandedCategory.value = expandedCategory.value === index ? null : index
}

const handleEscKey = (event: KeyboardEvent) => {
  if (event.key === 'Escape' && props.isOpen) {
    emit('close')
  }
}

onMounted(() => {
  document.addEventListener('keydown', handleEscKey)
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleEscKey)
})

watch(
  () => props.isOpen,
  () => {
    swipeDistance.value = 0
  },
  { immediate: true },
)
</script>
