<template>
  <div
    class="relative flex cursor-pointer items-center space-x-3 text-sm"
    @click="handleClick()"
  >
    <ZenniIconsIconUserAccount size="lg" class="text-grays-light" />
    <div class="flex flex-col">
      <span v-if="currentGift?.recipient" class="font-semibold">{{
        `${currentGift.recipient.firstName} ${currentGift.recipient.lastName}`
      }}</span>
      <div
        class="bg-grays-lighter text-grays-darkest mt-0.5 w-fit rounded-full px-2 py-0.5 text-center"
        :class="{ 'bg-purples-lightest text-purples-dark': amount > 0 }"
      >
        Credit Left: {{ creditLeft }}
      </div>
    </div>
    <ZenniIconsIconCaret
      v-if="dependents?.length"
      size="sm"
      class="-mt-1.5 rotate-180 self-start"
    />
    <ZenniIconsIconInformationCircle
      v-else
      class="stroke-purples-dark [&>path]:fill-purples-dark fill-none [&>path]:stroke-none"
      size="base"
    />
  </div>
  <ProfileDrawer v-if="showProfileDrawer" @close="showProfileDrawer = false" />
  <ZnModal
    size="small"
    :show-close-icon="false"
    :show="showCreditModal"
    @close="showCreditModal = false"
  >
    <template #title>
      <h1 class="text-lg font-semibold">How credit works</h1>
    </template>
    <template #body>
      <ul class="mt-5 list-disc space-y-5 px-6">
        <li>
          Credit is automatically applied to your account and works like a gift
          card - feel free to apply it across multiple purchases.
        </li>
        <li>
          Credit can be applied to all products from our current catalog,
          including taxes and shipping.
        </li>
        <li>You're responsible for any costs over your credit amount.</li>
        <li>
          Credit must be used by
          <span class="font-semibold">{{ giftEndDate }}</span> and does not
          carry over or transfer.
        </li>
      </ul>
      <div class="mt-5 flex">
        <ZnButton
          class="w-full text-center"
          type="secondary"
          @click="showCreditModal = false"
          >Close</ZnButton
        >
      </div>
    </template>
  </ZnModal>
  <LandingHouseholdOnboard
    :show-modal="showOnboardingModal"
    @close="showOnboardingModal = false"
  />
</template>

<script setup lang="ts">
import { format, parseISO } from 'date-fns'
import { storeToRefs } from 'pinia'
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { ref, computed, watch, useAsyncData } from '#imports'
import { useGuestStore } from '~/store/guest'
import { useOnboardStore } from '~/store/onboard'

const showProfileDrawer = ref<boolean>(false)
const showCreditModal = ref<boolean>(false)
const showOnboardingModal = ref<boolean>(false)

const guestStore = useGuestStore()
const { dependents, currentGift } = storeToRefs(guestStore)
useAsyncData('dependents', async () => {
  if (guestStore.dependents === null) {
    await guestStore.loadDependents()
  }
})

const amount = computed<number>(() =>
  currentGift.value ? guestStore.calculateGiftAmt(currentGift.value) : 0,
)

const creditLeft = computed<string>(() =>
  formatCurrency(amount.value, 'en-US', {
    currency: 'USD',
    minimumFractionDigits: 2,
  }),
)

const giftEndDate = computed<string>(() => {
  const { to } = currentGift.value?.schedule?.date ?? {}
  if (to) {
    return format(parseISO(to), 'MM/dd/yyyy')
  }
  return '--'
})

const onboardStore = useOnboardStore()
watch(
  () => dependents.value,
  () => {
    if (dependents.value?.length && !onboardStore.sessionOnboarded) {
      showOnboardingModal.value = true
    }
  },
  { immediate: true },
)

const handleClick = () => {
  if (dependents.value?.length) {
    showProfileDrawer.value = true
  } else {
    showCreditModal.value = true
  }
}
</script>
