<template>
  <div class="flex min-h-screen flex-col">
    <Header />
    <main class="relative flex flex-1 flex-col">
      <NuxtLoadingIndicator />
      <div class="flex flex-1">
        <slot />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useLazyAsyncData, useNuxtApp, useCookie } from '#imports'
import { useGuestCartStore } from '~/store/guestCart'
import { useGuestStore } from '~/store/guest'

const guestCartStore = useGuestCartStore()
const { guest } = storeToRefs(useGuestStore())

const guestCartCookie = useCookie('guestCart')

const nuxtApp = useNuxtApp()
useLazyAsyncData(
  'cart',
  async () => {
    if (guest.value.token || guest.value.loggedInToken) {
      const parsedCookie =
        typeof guestCartCookie.value === 'string'
          ? JSON.parse(guestCartCookie.value)
          : guestCartCookie.value

      const needsCart = !parsedCookie || (parsedCookie && !parsedCookie.cartId)

      if (guest.value && guest.value.storeId) {
        if (needsCart) {
          await guestCartStore.createCart()
        }

        nuxtApp.runWithContext(() =>
          guestCartStore.loadCart(parsedCookie?.cartId),
        )
      }
    }
  },
  {
    immediate: true,
    watch: [() => guest.value.token],
  },
)
</script>
